.circular-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: 20px auto;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.menu-item {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s;
}

.menu-item:hover {
  transform: scale(1.1);
}

.menu-item:nth-child(1) {
  top: 50%;
  left: 50%;
  transform: translate(-150%, -50%);
}

.menu-item:nth-child(2) {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.menu-item:nth-child(3) {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

.menu-item:nth-child(4) {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.menu-item:nth-child(5) {
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}

.menu-item:nth-child(6) {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

/* FAB Menu styles */
.fab-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.fab-menu {
  display: flex;
  gap: 10px;
}

.fab-button {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
