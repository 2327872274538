.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.job-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  width: 100%;  /*300px; */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.job-title {
  color: #333;
  margin-bottom: 10px;
}

.job-detail, .job-time {
  background-color: #fff;
  margin: 5px 0;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.job-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.walking-time {
  background-color: #e3f2fd;
}

.driving-time {
  background-color: #ffe0b2;
}

strong {
  color: #555;
}

.job-board-header {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
}

.job-board-header h1 {
  margin: 0;
  color: #333;
  font-size: 24px;
}

.job-board-header p {
  margin: 5px 0;
  color: #666;
}

.distance-filter{
    /* width: 100%; */
}

.job-card {
  /* existing styles */
  position: relative;
}

.job-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.apply-button {
  padding: 10px 20px;
  background-color: #4CAF50; /* Green */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.apply-button:hover {
  background-color: #45a049;
}