/* .legend {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.legend-item {
  width: 100px;
  height: 25px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.legend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 200px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.legend-item:last-child {
  margin-bottom: 0;
}

.color-box {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 10px;
  border: 1px solid #ddd;
}

.label {
  color: #333;
}
