.icon-menu {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
}

.menu-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    color: white;
}

.icon {
    /* Style your icon here */
}
