.form-container {
    max-width: 900px;
    margin: 0 auto;
  }
  
  .form-container input,
  .form-container textarea {
    width: 75%;
    padding: 8px;
    margin-bottom: 15px;
  }
  