/* invoiceadmin.css */

/* General page styling */
.page-content-admin {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #333;
  padding: 50px;
  background: #f5f5f7;
  min-height: 100vh;
}

/* Header */
.admin-header {
  text-align: center;
  margin-bottom: 30px;
}

.admin-header h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #1d1d1f;
  margin-bottom: 10px;
}

.admin-subtitle {
  font-size: 1.2rem;
  color: #6e6e73;
}

/* Grid layout for invoices */
.admin-invoice-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

/* Card styling */
.admin-invoice-card {
  background: #ffffff;
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.admin-invoice-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Card content */
.invoice-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1d1d1f;
  margin-bottom: 5px;
}

.invoice-subtitle {
  font-size: 1rem;
  color: #6e6e73;
  margin-bottom: 15px;
}

.invoice-date {
  font-size: 0.9rem;
  color: #8e8e93;
  margin-bottom: 15px;
}

/* Button styling */
button {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 8px;
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.no-invoices-message {
  text-align: center;
  font-size: 1.2rem;
  color: #8e8e93;
}
