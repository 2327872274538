/* Hero Section */
.hero-section {
  background-color: #007bff;
  color: #fff;
}

.hero-section h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.hero-section p {
  font-size: 1.2rem;
}

/* Contact Form Section */
.contact-form-section form {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-form-section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

/* Map Section */
.map-section iframe {
  border-radius: 8px;
}
