.user-pill {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 999px;
  background-color: #eee;
  cursor: pointer;
  margin: 5px;
}

.username {
  font-weight: bold;
}

.user-details {
  margin-top: 4px;
}
