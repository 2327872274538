/* Styling the attachments section */
.attachments-section, .new-attachments-section, .add-attachment-section {
  margin-top: 20px;
}

.attachments-title, .add-attachment-title, .new-attachments-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.attachments-list, .new-attachments-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.attachment-item, .new-attachment-item {
  margin-bottom: 10px;
}

.attachment-link {
  color: #007bff;
  text-decoration: none;
  font-size: 1rem;
  border-bottom: 1px dotted #007bff;
}

.attachment-link:hover {
  text-decoration: underline;
}

.no-attachments {
  color: #dc3545;
  font-size: 1rem;
  margin-top: 10px;
}

/* Styling file input */
.attachment-input {
  border: 1px solid #ced4da;
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  margin-top: 10px;
  /* background-color: #f8f9fa; */
  cursor: pointer;
}

/* Button styling for saving attachments */
.save-attachments-button {
  margin-top: 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.save-attachments-button:hover {
  background-color: #218838;
}

/* Add this CSS to style your search box */
.search-bar input {
  width: 100%;
  padding: 12px 20px;
  border-radius: 24px;
  border: 1px solid #ddd;
  font-size: 16px;
  outline: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: box-shadow 0.3s ease;
}

.search-bar input::placeholder {
  color: #999;
  font-size: 14px;
  text-align: center;
}

.search-bar input:focus {
  border: 1px solid #4285F4; /* Google Blue */
  box-shadow: 0px 4px 12px rgba(66, 133, 244, 0.3); /* Blue shadow on focus */
}

.search-bar input:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Slightly darker shadow on hover */
}
