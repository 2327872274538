.doccontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.6em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
    color: white;
}

.badge-new { background-color: #007bff; }
.badge-progress { background-color:  rgb(94, 110, 2); }
.badge-draft { background-color: #6c757d; }
.badge-suspended { background-color: #ffc107; }
.badge-validated { background-color: #28a745; }
.badge-approved { background-color: #28a745; }
.badge-terminated { background-color: #dc3545; }

.error-class {
    border-color: red;
}
.error-message {
    color: red;
    font-size: 0.8em;
}
