/* Header.css */

.header-shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
}

.caret-icon {
  font-size: 0.75em;
  margin-left: 4px;
}
