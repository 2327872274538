/* .page-content {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

h2, h3 {
  color: #333;
}

form label {
  display: block;
  margin-bottom: 10px;
}

/* input[type="text"],
input[type="email"],
input[type="number"],
input[type="date"],
textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
} */

textarea {
  height: 100px;
}


button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #45a049;
}

.removebtn{
  background-color: #f90707;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* margin-top: 10px; */
  height: 70%;
  top: -4px;
  position: relative;
  margin: 4px;
}

.removebtn:hover {
  background-color: #c93a3a;
}
/* 
.line-item {
  background: #fff;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #eee;
  border-radius: 4px;

} */

.line-item input {
  margin-right: 10px;
  margin-bottom: 5px;
}


.form-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form-group label {
  flex: 1 1 45%; /* Use half the width */
  margin-bottom: 10px;
}

.input-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.right-input-group{
    float: right;
}

.input-group label {
  flex: 0 0 100%;
}
.line-item {
  display: grid;
  grid-template-columns: 0.2fr repeat(7, 2fr); /* first column smaller */
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.line-item input, .line-item button {
  width: 100%;
}

/* .totals {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
}

.totals div {
  margin-bottom: 5px;
} */

.totals {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* margin-right: 200px; */
  margin-right: -40%;
}



.totals span {
  /* Style for the spans if necessary
  width: 10px; */
}
