.invoice-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Adjust the number of columns as needed */
  gap: 10px;
}

.invoice-card {
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
}

.invoice-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1000;
  /* Rest of the modal styling */
}


.invoice-card {
  flex: 0 1 300px; /* Adjust width as needed */
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.invoice-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.invoice-card h3 {
  margin-top: 0;
  color: #333;
}

.invoice-card p {
  margin-bottom: 5px;
  color: #666;
}


/* Modal Overlay */
.invoice-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal Container */
.invoice-modal-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 70%;
  max-height: 90%;
  overflow-y: auto;
}

/* Table Styling */
.invoice-modal-container table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.invoice-modal-container th, .invoice-modal-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* Button Styling */
.invoice-modal-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.invoice-modal-button:hover {
  background-color: #0056b3;
}
