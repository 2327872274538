/* General form styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #333;
  background-color: #f5f5f7;
  margin: 0;
  padding: 20px;
}

h1 {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 600;
  color: #333;
  margin-bottom: 40px;
}

label {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #555;
}

input,
select,
textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  background-color: #fff;
  color: #333;
  transition: box-shadow 0.2s ease;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 132, 255, 0.5);
  border-color: #0084ff;
}

button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #0084ff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 132, 255, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  background-color: #006bbf;
  box-shadow: 0 6px 15px rgba(0, 132, 255, 0.4);
}

.invoice-form {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.invoice-form div {
  margin-bottom: 20px;
}

.invoice-preview {
  margin-top: 40px;
  padding: 40px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.invoice-preview h2 {
  font-size: 2rem;
  color: #333;
  font-weight: 600;
}

table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

table th,
table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

table th {
  font-weight: 600;
  color: #333;
}

table td {
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .invoice-form, .invoice-preview {
    padding: 20px;
  }

  h1 {
    font-size: 2rem;
  }
}

/* Line Item Grouping */
.line-item-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.line-item-group div {
  flex: 1;
}

.line-item-group label {
  font-size: 0.9rem;
  color: #555;
}

.line-item-group input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  font-size: 1rem;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.2s ease;
}

.line-item-group input:focus {
  outline: none;
  border-color: #0084ff;
}

