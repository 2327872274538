/* invoiceagent.css */

/* General page styling */
.page-content {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #333;
  padding: 50px;
  background: #f5f5f7;
  min-height: 100vh;
}

/* Header styling */
.agent-header {
  text-align: center;
  margin-bottom: 30px;
}

.agent-header h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #1d1d1f;
  margin-bottom: 10px;
}

.agent-subtitle {
  font-size: 1.2rem;
  color: #6e6e73;
}

/* Upload Button */
.upload-btn {
  display: block;
  margin: 0 auto 20px;
  background-color: #007aff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.upload-btn:hover {
  background-color: #005bb5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Invoice grid */
.invoice-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Invoice card */
.invoice-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.invoice-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.invoice-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1d1d1f;
  margin-bottom: 5px;
}

.invoice-date {
  font-size: 0.9rem;
  color: #8e8e93;
  margin-bottom: 10px;
}

.view-invoice-link {
  font-size: 0.9rem;
  color: #007aff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.view-invoice-link:hover {
  color: #005bb5;
}

/* No invoices message */
.no-invoices-message {
  text-align: center;
  font-size: 1.2rem;
  color: #8e8e93;
}
