.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center
{
    position: absolute;
}

.dx-theme-generic-typography a {
    color: blue !important;
}

.agreement-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.agreement-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa; /* Light background */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.agreement-details {
  flex-grow: 1;
}

.agreement-link {
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
}

.agreement-link:hover {
  text-decoration: underline;
}

.delete-btn {
  margin-left: 20px;
}
