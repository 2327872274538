/* Hero Section */
.hero-section {
  color: #fff;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}

.hero-section h1 {
  font-size: 3rem;
  font-weight: bold;
}

.hero-section p {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.hero-section button {
  font-size: 1.1rem;
  padding: 0.75rem 1.5rem;
}

/* Culture Section */
.culture-section .card {
  transition: transform 0.3s, box-shadow 0.3s;
}

.culture-section .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.culture-section .card img {
  object-fit: cover;
  height: 200px;
  border-radius: 10px 10px 0 0;
}

.culture-section .card-title {
  font-size: 1.2rem;
  font-weight: bold;
}

/* Testimonials Section */
.testimonials-section .card {
  border: none;
  background-color: #f8f9fa;
}

.testimonials-section .card p {
  margin: 0;
}
