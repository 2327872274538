.card-img-top {
  object-fit: cover;
  height: 200px;
  border-radius: 10px 10px 0 0;
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}
