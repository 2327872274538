/* Base styling for the team card */
.team-card {
  perspective: 1000px;
}

.team-card-inner {
  position: relative;
  width: 100%;
  height: 250px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.team-card:hover .team-card-inner {
  transform: rotateY(180deg);
}

.team-card-front,
.team-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 15px;
}

.team-card-front img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.team-card-back {
  background-color: #f8f9fa;
  transform: rotateY(180deg);
  text-align: center;
  padding: 20px;
}
