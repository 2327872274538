.hero-section {
  background: linear-gradient(to right, #4caf50, #2e7d32);
}

.resources-section .card {
  transition: transform 0.3s;
}

.resources-section .card:hover {
  transform: scale(1.05);
}
