/* Hero Section */
.hero-section {
  color: #fff;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}

.hero-section h1 {
  font-size: 3rem;
  font-weight: bold;
}

.hero-section p {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.hero-section button {
  font-size: 1.1rem;
  padding: 0.75rem 1.5rem;
}

/* Latest News Section */
.latest-news-section .card {
  transition: transform 0.3s, box-shadow 0.3s;
}

.latest-news-section .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.latest-news-section .card img {
  object-fit: cover;
  height: 200px;
  border-radius: 10px 10px 0 0;
}

.latest-news-section .card-title {
  font-size: 1.2rem;
  font-weight: bold;
}

/* Media Resources Section */
.media-resources-section h5 {
  font-size: 1.2rem;
  font-weight: bold;
}

/* Call-to-Action Section */
.cta-section {
  background-color: #f8f9fa;
  padding: 3rem 0;
}
