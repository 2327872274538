.footerx {
  background-color: #f8f9fa;
  color: #333;
}

.footer-logo {
  height: 50px;
}

.footer-catchphrase {
  font-size: 1rem;
  font-weight: bold;
  color: #555;
}

.footer-heading {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.footerx a {
  text-decoration: none;
  color: #007bff;
}

.footerx a:hover {
  text-decoration: underline;
}
