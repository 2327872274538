.nav-link {
    cursor: pointer;
    padding: 10px; /* Example padding, adjust as needed */
}

.nav-link.active {
    color: white;
    background-color: blue;
}

/* Booking.css */
.table-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.table-row:nth-child(odd) {
  background-color: #f9f9f9; /* Light grey for odd rows */
}

.table-row:nth-child(even) {
  background-color: #fff; /* White for even rows */
}

.table-row:hover {
  background-color: #e0e0e0; /* Darker grey on hover */
}

.nav-item nav-link active {
  color: #eee;
}

.nav .nav-tabs {
  color: #fff
}